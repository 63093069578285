import{get,del, post,put}from"./base";

// 获取功能模块
export function getFunctionModule(data){
    return get("/v3/functionModule",data);
}
// 新增
export function postFunctionModule(data){
    return post("/v3/functionModule",data);
}
// 修改
export function putFunctionModule(data){
    return put("/v3/functionModule",data);
}
// 删除
export function delFunctionModule(id) {
  return del(`/v3/functionModule/${id}`);
}
// 获取小程序首页功能管理  
export function getHomefunction(data){
    return get("/v3/homefunction",data);
}
// 添加
export function postHomefunction(data){
    return post("/v3/homefunction",data);
}
// 修改  
export function putHomefunction(data){
    return put("/v3/homefunction",data);
}
// 删除
export function delHomefunction(id) {
    return del(`/v3/homefunction/${id}`);
}
// 上移下移
export function moveHomefunction(type,id) {
    return put(`/v3/homefunction/${type}/${id}`);
}
// banner推荐管理
export function getBannerLocation(bannerLocation) {
    return get(`/v3/banner/type/${bannerLocation}`);
}
// 获取banner推荐  
export function getRecommend(data){
    return get("/v3/banner/recommend",data);
}
// 移动 
export function moveRecommend(type,id) {
    return put(`/v3/banner/recommend/${type}/${id}`);
}
// 移除
export function delRecommend(id) {
    return del(`/v3/banner/recommend/${id}`);
}